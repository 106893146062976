.hdrImg {
	width: 100%;
	max-width: 100%;
	height: auto;
}
.headline {
	font-family: 'exoBold';
	font-size: 24px;
	line-height: 30px;
	color: #000;
	text-align: center;
}
.bdyText {
	font-family: 'exoSemi';
	font-size: 14px;
	line-height: 18px;
	text-align: justify;
}
.spnDiv {
	width: 86%;
	padding: 10px;
	margin-left: auto;
	margin-right: auto;
	margin-bottom: 20px;
	border-radius: 20px;
}
.spnDiv:hover {
	cursor: pointer;
}
.spnImg {
	width: 94%;
	height: auto;
	margin-left: auto;
	margin-right: auto;
}
.btnDiv {
	width: 94%;
	padding: 20px;
	margin-bottom: 20px;
	font-family: 'exoSemi';
	font-size: 18px;
	line-height: 24px;
	color: #000;
	text-align: center;
	border-radius: 20px;
}
.btnDiv:hover {
	cursor: pointer;
	color: blue;
}
.video-container {
	position: relative;
	padding-bottom: 56.25%;
	padding-top: 30px;
	height: 0;
	overflow: hidden;
}
.video-container iframe,
.video-container object,
.video-container embed {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
}
.socHdng {
	font-family: 'exoBold';
	font-size: 17px;
	line-height: 23px;
}
.socDiv {
	margin-left: 15px;
	margin-right: 15px;
	text-align: center;
}
.trvDiv {
	width: 94%;
	margin-left: auto;
	margin-right: auto;
}
.trvQst {
	font-family: 'exoBold';
	font-size: 17px;
	line-height: 23px;
	color: blue;
}
.trvAns {
	font-family: 'exoBold';
	font-size: 17px;
	line-height: 23px;
	color: red;
}
.plyrLink {
	font-family: 'exoSemi';
	font-size: 15px;
	line-height: 21px;
	text-decoration: none;
	color: blue;
}
.plyrLink:hover {
	color: red;
	cursor: pointer;
}
.plyrText {
	font-family: 'exoSemi';
	font-size: 15px;
	line-height: 21px;
}
.prfImg {
	width: 94%;
	margin-left: auto;
	margin-right: auto;
}
.qpnImg {
	width: 98%;
	margin-left: auto;
	margin-right: auto;
}
.twobtnDiv {
	padding: 40px 20px;
	border-radius: 20px;
}
