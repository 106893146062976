/*** FORGOT PASSWORD LINK ***/
.fpLink {
	font-size: 15px;
	line-height: 21px;
	font-weight: bold;
	color: #000;
	text-decoration: none;
}
.fpLink:hover {
	color: #9b00ef;
	cursor: pointer;
}

/*** CANCEL LINK ***/
.cncLink {
	font-size: 14px;
	line-height: 20px;
	font-weight: bold;
	color: #000;
	text-decoration: none;
}
.cncLink:hover {
	color: #9b00ef;
	cursor: pointer;
}

/*** ADMIN ICON STYLES ***/
.admIcoSm {
	max-height: 22px;
}
