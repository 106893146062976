.hmpgImg {
	display: block;
	width: 30%;
	margin-left: auto;
	margin-right: auto;
}

.cardTitle {
	font-family: 'exoSemi';
	color: #9b00ef;
}

.cardText {
	font-size: 15px;
	line-height: 19px;
	font-weight: 500;
}

@media screen and (max-width: 991px) {
	.hmpgImg {
		width: 40%;
	}
}

@media screen and (max-width: 767px) {
	.hmpgImg {
		width: 60%;
	}
}

@media screen and (max-width: 479px) {
	.hmpgImg {
		width: 80%;
	}
}
