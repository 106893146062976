.backText {
	font-family: 'exoBold';
	font-size: 14px;
	line-height: 20px;
	color: #000;
	text-decoration: none;
}
.backText:hover {
	color: #9b00ef;
	cursor: pointer;
}
