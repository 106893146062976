.backText {
	font-family: 'exoBold';
	font-size: 14px;
	line-height: 20px;
	color: #000;
	text-decoration: none;
}
.backText:hover {
	color: #9b00ef;
	cursor: pointer;
}

.tmpImg {
	max-width: 150px;
}

.tmpName {
	margin-top: 10px;
	font-size: 22px;
	line-height: 28px;
	font-weight: 600;
	color: #9b00ef;
}
