.cardImg {
	max-width: 137px;
	margin-left: auto;
	margin-right: auto;
	margin-bottom: 20px;
}

.lnkText {
	font-family: 'exoBold';
	font-size: 15px;
	line-height: 21px;
	text-decoration: none;
}

.admLink {
	width: 157px;
	margin-left: auto;
	margin-right: auto;
	color: #000;
	text-decoration: none;
}

.admLink:hover {
	color: #9b00ef;
	cursor: pointer;
}
