.fullContainer {
	width: 96%;
	margin-left: auto;
	margin-right: auto;
}

.lgContainer {
	width: 80%;
	max-width: 1400px;
	margin-left: auto;
	margin-right: auto;
}

.mdContainer {
	width: 60%;
	max-width: 1000px;
	margin-left: auto;
	margin-right: auto;
}

.smContainer {
	width: 40%;
	max-width: 600px;
	margin-left: auto;
	margin-right: auto;
}

@media (max-width: 1440px) {
	.lgContainer {
		width: 96%;
	}

	.mdContainer {
		width: 80%;
	}

	.smContainer {
		width: 60%;
	}
}

@media (max-width: 1025px) {
	.mdContainer {
		width: 96%;
	}

	.smContainer {
		width: 80%;
	}
}

@media (max-width: 610px) {
	.smContainer {
		width: 96%;
	}
}
