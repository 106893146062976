/*** FORM BUTTON STYLES ***/
.button {
	width: auto;
	padding: 7px 20px;
	color: #000;
	border: 2px solid #000;
	border-radius: 10px;
	background-color: transparent;
	font-family: 'exoBold';
	font-size: 18px;
}
.button:hover {
	border: 2px solid #9b00ef;
	color: #9b00ef;
	cursor: pointer;
}
.button:disabled {
	color: #d4d4dc;
	border: 1px solid #d4d4dc;
	cursor: default;
}

/*** LOGO IMAGE ***/
.logoImg {
	max-width: 120px;
}
.logoImg:hover {
	cursor: pointer;
}

/*** FORM CHECKBOX STYLES ***/
.chkBox {
	width: 22px;
	height: 22px;
	accent-color: #9b00ef;
}

/*** PROMO FORM SYTLES ***/
.prmHdng {
	font-size: 20px;
	line-height: 26px;
	font-weight: 700;
	color: #9b00ef;
}
.prmColHdng {
	font-size: 18px;
	line-height: 24px;
	font-weight: 600;
	color: #000;
	text-decoration: underline;
}

/*** ROBOT PREVENTION INPUT ***/
.testinput {
	display: none;
}
