.lgShadowBox {
	width: 80%;
	border-radius: 7px;
}

.mdShadowBox {
	width: 55%;
	border-radius: 7px;
}

.smShadowBox {
	width: 30%;
	border-radius: 7px;
}

.tnyShadowBox {
	min-width: 320px;
	max-width: 630px;
	width: 100%;
}

@media (max-width: 1200px) {
	.mdShadowBox {
		width: 75%;
	}

	.smShadowBox {
		width: 55%;
	}
}

@media (max-width: 768px) {
	.lgShadowBox {
		width: 96%;
	}

	.mdShadowBox {
		width: 96%;
	}

	.smShadowBox {
		width: 80%;
	}
}
