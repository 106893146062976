.bgLarge {
	width: 100vw;
	min-height: 100vh;
	background-image: url('../../assets/images/bgLg.jpg');
	background-position: left top;
	background-size: contain;
	background-repeat: repeat;
}
.bgMedium {
	width: 100vw;
	min-height: 95vh;
	background-image: url('../../assets/images/bgMd.jpg');
	background-position: left top;
	background-size: contain;
	background-repeat: repeat-y;
}
.bgSmall {
	width: 100vw;
	min-height: 95vh;
	background-image: url('../../assets/images/bgSm.jpg');
	background-position: left top;
	background-size: contain;
	background-repeat: repeat-y;
}
