.txtTinyUrl {
	font-family: 'exoBold';
	font-size: 23px;
	line-height: 29px;
	color: #9b00ef;
}
.smAdd {
	max-height: 23px;
}
.samplesLink {
	font-family: 'exoSemi';
	font-size: 15px;
	line-height: 21px;
	color: #000;
	text-decoration: none;
}
.samplesLink:hover {
	color: #9b00ef;
	cursor: pointer;
}
.colHdng {
	font-size: 20px;
	line-height: 26px;
	font-weight: 600;
	color: #660e0e;
	text-decoration: underline;
}
.admInfo {
	font-family: 'exoSemi';
	font-size: 12px;
	line-height: 18px;
	color: #9b00ef;
}
.saveLink {
	font-family: 'exoSemi';
	font-size: 14px;
	line-height: 20px;
	color: black;
}
.saveLink:hover {
	color: #9b00ef;
	cursor: pointer;
}
.chkBox {
	width: 22px;
	height: 22px;
	accent-color: #9b00ef;
}
.icoAct {
	max-height: 22px;
}
.icoView {
	max-height: 22px;
}
.icoQR {
	max-height: 22px;
}
.backText {
	font-family: 'exoBold';
	font-size: 14px;
	line-height: 20px;
	color: #000;
	text-decoration: none;
}
.backText:hover {
	color: #9b00ef;
	cursor: pointer;
}
