body {
	font-family: 'exoMed';
	font-size: 15px;
	line-height: 21px;
	overflow-x: hidden;
}

.textBold {
	font-family: 'exoBold';
}

.textSemi {
	font-family: 'exoSemi';
}

.textMed {
	font-family: 'exoMed';
}

.textReg {
	font-family: 'exoReg';
}

.noRecsText {
	font-family: 'exoBold';
	font-size: 16px;
	line-height: 21px;
	color: #9b00ef;
}

/******************************************************************************************************************************/
/*** UNIVERSAL FORM STYLES ***/
/******************************************************************************************************************************/
.formContainer {
	width: 40%;
}
.frmLabel {
	font-family: 'exoBold';
	font-size: 14px;
	line-height: 20px;
}
.listRow {
	border-bottom: 1px solid #dbdbea;
}
.listLinkText {
	font-family: 'exoSemi';
	font-size: 14px;
	line-height: 20px;
	text-decoration: none;
}
.listLinkText:hover {
	color: #9b00ef;
	cursor: pointer;
}
.listInfoText {
	font-family: 'exoSemi';
	font-size: 14px;
	line-height: 20px;
}

@media screen and (max-width: 768px) {
	.formContainer {
		width: 90%;
	}
}

/******************************************************************************************************************************/
/*** DELETE CONFIRMATION BOX STYLES ***/
/******************************************************************************************************************************/
.confirm-bg {
	position: fixed;
	display: none;
	left: 0;
	top: 0;
	width: 100%;
	height: 100%;
	background-color: #0070cc;
	opacity: 0.3;
	overflow: hidden;
	z-index: 2;
}
.confirmation-container {
	display: none;
	flex-direction: column;
	position: fixed;
	background-color: #0070cc;
	width: 230px;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -75%);
	border-radius: 0.3rem;
	padding: 1rem;
	z-index: 5; /* Higher than the z-index of the background */
}
.confirmation-text {
	display: flex;
	color: white;
	margin: 0.5rem 0 2rem;
	text-align: center;
	line-height: 2rem;
	font-size: 1.1rem;
}
.button-container {
	display: flex;
	margin-top: auto;
	justify-content: space-between;
}
.confirmation-button {
	display: inline-flex;
	background-color: #ff7373;
	color: white;
	padding: 0.7rem 1.4rem;
	border: none;
	border-radius: 0.3rem;
	font-size: 1rem;
}
.cancel-button {
	display: inline-flex;
	background-color: #ececfb;
	padding: 0.7rem 1.4rem;
	border: none;
	border-radius: 0.3rem;
	font-size: 1rem;
}
.confirmation-button:hover {
	background-color: #ff2626;
	cursor: pointer;
}
.cancel-button:hover {
	background-color: #cacad9;
	cursor: pointer;
}

/******************************************************************************************************************************/
/*** FONTS ***/
/******************************************************************************************************************************/
@font-face {
	font-family: 'exoReg';
	src: local('exoReg'), url('../src/assets/fonts/Exo-Regular.ttf') format('truetype');
	font-weight: normal;
}

@font-face {
	font-family: 'exoMed';
	src: local('exoMed'), url('../src/assets/fonts/Exo-Medium.ttf') format('truetype');
	font-weight: normal;
}

@font-face {
	font-family: 'exoSemi';
	src: local('exoSemi'), url('../src/assets/fonts/Exo-SemiBold.ttf') format('truetype');
	font-weight: normal;
}

@font-face {
	font-family: 'exoBold';
	src: local('exoBold'), url('../src/assets/fonts/Exo-Bold.ttf') format('truetype');
	font-weight: normal;
}

@font-face {
	font-family: 'exoXBld';
	src: local('exoXBld'), url('../src/assets/fonts/Exo-ExtraBold.ttf') format('truetype');
	font-weight: normal;
}
