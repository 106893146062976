.icoAdd {
	max-height: 25px;
}
.icoAdd:hover {
	cursor: pointer;
}

.icoDelete {
	max-height: 22px;
}
.icoDelete:hover {
	cursor: pointer;
}

.icoLevel {
	max-height: 22px;
}
.icoLevel:hover {
	cursor: pointer;
}
